<template>
  <div id="popupWrapper">
    <ejs-dialog
      allowDragging="true"
      header="권한 그룹 생성"
      isModal="true"
      ref="dialog"
      showCloseIcon="true"
      width="550"
      v-bind="dialogProps"
      @close="onDialogClose"
      @overlayClick="onDialogOverlayClick"
    >
      <div class="window authorityGroupCreate">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">ID</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="groupId"
                                        v-model="groupId"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">그룹명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="groupName"
                                        v-model="groupName"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">설명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        v-model="description"
                                        maxlength="400"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">정렬</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text v-model="order" />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="create keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :is-custom-shortcut-button="true"
                  shortcut-key="createAuthorityGroupPopup.shortcuts.submitClick"
                  :shortcut="{key: 'F3'}"
                  @click.native="onSubmitClick"
              >
                생성
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="onCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import InputText from "@/components/common/text/InputText";
import { validateFormRefs } from "@/utils/formUtil";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "CreateAuthorityGroupPopup",
  components: { InputText,  ErpButton, },
  props: {
    nextOrder: {
      type: Number,
    },
  },
  mounted() {
    this.show();
  },
  data() {
    return {
      groupId: "",
      groupName: "",
      description: "",
      order: this.nextOrder,
      validateRefList: {
        groupId: {
          required: true,
        },
        groupName: {
          required: true,
        },
      },
    };
  },
  computed: {
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
      };
    },
  },
  methods: {
    validateFormRefs,
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onDialogOverlayClick() {
      this.hide();
    },

    onSubmitClick() {
      if (!this.validate()) {
        return;
      }

      this.$emit("submit", {
        id: this.groupId,
        name: this.groupName,
        description: this.description,
        order: this.order,
      });

      this.hide();
    },
    onCloseClick() {
      this.hide();
    },

    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
};
</script>
