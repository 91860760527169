<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">권한 그룹 찾기</div>
            <ul class="content">
              <li class="item dateRange">
                <input-text
                  ref="input-search-groups-query"
                  v-model="searchGroupsQuery"
                  @keydown.enter.native="onSearchGroupsClick"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onSearchGroupsClick"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article
          class="body-article"
          :class="
          isOpen
            ? $t('className.reservation.reservationDetailViewOpened')
            : ''
        "
      >
              <div class="article-left">
                <div class="section-header">
                  <div class="header-left">
                    <div class="header-title">권한 그룹 목록</div>
                    <div class="header-caption">[{{ 0 }}건]</div>
                  </div>
                  <div class="header-right">
                    <ul class="header-button">
                      <li class="create">
                        <erp-button
                            button-div="GET"
                            :ignore="isPopupOpened"
                            :is-icon-custom="true"
                            :is-custom-shortcut-button="true"
                            shortcut-key="authorityGroupsManagement.shortcuts.addAuthorityGroup"
                            :shortcut="{key: 'F3'}"
                            @click.native="onCreateAuthorityGroupClick"
                        >
                          권한 그룹 생성
                        </erp-button>
                      </li>
                      <li class="settings">
                        <erp-button
                            button-div="GET"
                            :is-icon-custom="true"
                            @click.native="onSetAuthorityGroupAuthoritiesClick"
                        >
                          권한 설정
                        </erp-button>
                      </li>
                      <li class="delete">
                        <erp-button
                            button-div="DELETE"
                            :is-shortcut-button="true"
                            @click.native="onDeleteAuthorityGroupClick"
                        >
                          권한 그룹 삭제
                        </erp-button>
                      </li>
                      <li class="print">
                        <erp-button
                            button-div="FILE"
                            @click.native="onClickExcel"
                        >
                          Excel
                        </erp-button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="section-body" style="height: calc(100% - 100px);">
                  <ejs-grid-wrapper
                      ref="authorityGroupsGrid"
                      v-bind="authorityGroupsGridProps"
                      @headerCellInfo="onAuthorityGroupsGridHeaderCellInfo"
                      @queryCellInfo="onAuthorityGroupsGridQueryCellInfo"
                      @cellSave="onAuthorityGroupsGridQueryCellSave"
                      @cellSaved="onAuthorityGroupsGridQueryCellSaved"
                      @recordClick="onAuthorityGroupsGridRecordClick"
                  />
                </div>
              </div>
              <div class="article-right">
                <div class="section-header">
                  <div class="header-left">
                      <div class="header-title" style="font-size: 14px ; margin: 7px 15px 0px 15px;">
                        {{ treeViewTitle.groupInfo }} ( {{ treeViewTitle.groupName }} )
                      </div>
                      <div class="content comboBox">
                        <menu-search-combo-box @openNewMenu="exportMenu" />
                      </div>
                  </div>
                  <div class="header-right">
                    <ul class="header-button">
                      <li class="save keyColor">
                        <erp-button
                            button-div="SAVE"
                            :is-key-color="true"
                            :is-shortcut-button="true"
                            @click.native="save"
                          >
                          저장
                        </erp-button>
                      </li>
                      <li>
                        <erp-button
                            button-div="GET"
                            :is-icon-custom="true"
                            @click.native="treeViewAllOpen"
                        >
                          전체펼치기
                          </erp-button>
                      </li>
                      <li>
                        <erp-button
                            button-div="GET"
                            :is-icon-custom="true"
                            @click.native="treeViewAllClose"
                        >
                          전체닫기
                        </erp-button>
                      </li>
                      <li class="close">
                        <erp-button
                            button-div="CLOSE"
                            @click.native="closeTreeView"
                        >
                          닫기
                        </erp-button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="control_wrapper">
                  <ejs-treeview
                    ref="treeview"
                    id="treeview"
                    :fields="fields"
                    :showCheckBox='true'
                    :selectedNodes="selectTreeView"
                    :nodeChecked="treeCheck"
                    :loadOnDemand="false"
                    :expandOn="'Click'"
                  />
                </div>
              </div>
      </article>
    </div>
    <create-authority-group-popup
      :next-order="nextOrder"
      v-if="visibleCreateAuthorityGroupPopup"
      @submit="onCreateAuthorityGroupPopupSubmit"
      @close="onCreateAuthorityGroupPopupClose"
    />
    <update-authority-group-authorizations-popup
      :group-id="groupIdCreateAuthorityGroupAuthorizationsPopup"
      :group-name="groupNameCreateAuthorityGroupAuthorizationsPopup"
      v-if="visibleCreateAuthorityGroupAuthorizationsPopup"
      @close="onCreateAuthorityGroupAuthorizationsPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .e-treeview::v-deep .e-list-parent .e-level-3 > .e-text-content .e-list-text { font-weight: bold; }
body .appContent .body-article .e-treeview::v-deep .e-level-4 .e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
  background: #174e35;
}
body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%;}
body .appContent .body-article .article-right{margin-right: calc(-50% - 20px)}
body .appContent .body-article.dev-reservation-detail-view-opened .article-left{width:calc(50% - 10px)}
body .appContent .body-article.dev-reservation-detail-view-opened .article-right{width:calc(50% - 10px); margin:0}
.section-header-title {
  margin: 0;
  padding: 0;
  border-top: 1.5px solid #ccc;
}
body .comboBox {overflow: auto; float: left; width: 200px; height: 100%; margin: 0;  transition: all 0.3s ease-out;}
body .comboBox >>> .sideLeft-search {position: absolute; z-index: 100; }
body .comboBox >>> .sideLeft-search .search {position: relative; width: 100%;}
body .comboBox >>> .sideLeft-search .search .value {box-sizing: border-box; position: relative; height: 28px; border: 1px solid #e0e0e0; border-radius: 3px; background-color: #fff;}
body .comboBox >>> .sideLeft-search .search .value input {box-sizing: border-box; box-shadow: none; width: 100%; height: 100%; padding: 0 36px 0 12px; margin: 0; border: none; border-radius: 0; background-color: transparent; color: #666; font-size: 12px; font-family: '돋움', Dotum, Arial, Verdana, sans-serif;}
body .comboBox >>> .sideLeft-search .search .value button {overflow: hidden; position: absolute; top: 0; right: 0; width: 36px; height: 100%; box-sizing: border-box; box-shadow: none; border: none; border-radius: 0; background: transparent url('../../assets/images/common/button-icon-search.png') no-repeat center center; z-index: 10; cursor: pointer; text-indent: -1000px;}
body .comboBox >>> .sideLeft-search .search .list {display: none; box-sizing: border-box; position: absolute; top: 27px; left: 0; list-style: none; padding: 8px 0; margin: 0; width: 100%; border: 1px solid #e0e0e0; border-radius: 0 0 3px 3px; background-color: #fff; z-index: 10;}
body .comboBox >>> .sideLeft-search .search .list > li {padding: 4px 12px; color: #666; font-size: 12px; font-family: '돋움', Dotum, Arial, Verdana, sans-serif; line-height: 18px; cursor: pointer;}
body .comboBox >>> .sideLeft-search .search .list > li:hover {background-color: #f0f0f0;}
body .comboBox >>> .sideLeft-search .search.active .value {border-radius: 3px 3px 0 0;}
body .comboBox >>> .sideLeft-search .search.active .list {display: block;}
.section-header-title  .header-title {
  font-size: 13px !important;
}
body .appContent .body-article .section-body {overflow: visible;border: none;}
.body-article {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.control_wrapper {
  display: block;
  height: calc(100% - 100px);
  /*max-width: 450px;*/
  /*max-height: 350px;*/
  margin: auto;
  overflow: auto;
  border: 1px solid #dddddd;
  border-radius: 3px;
}
.custom .e-list-item .e-fullrow {
  height: 72px;
}

.custom .e-list-item .e-list-text {
  line-height: normal;
}

.eimage {
  float: left;
  padding: 11px 16px 11px 0;
}

.ename {
  font-size: 16px;
  padding: 14px 0 0;
}

.ejob {
  font-size: 14px;
  opacity: .87;
}
</style>

<script>
import { orderBy as _orderBy, maxBy as _maxBy, groupBy as _groupBy } from "lodash";
import commonMixin from "@/views/layout/mixin/commonMixin";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { Edit, Resize, ExcelExport } from "@syncfusion/ej2-vue-grids";
import GolfERPService from "@/service/GolfERPService";
import CreateAuthorityGroupPopup from "@/views/authority-management/popup/CreateAuthorityGroupPopup";
import UpdateAuthorityGroupAuthorizationsPopup from "@/views/authority-management/popup/UpdateAuthorityGroupAuthorizationsPopup";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import menuSearchComboBox from "@/views/layout/components/menuSearchComboBox.vue";
import ErpButton from "@/components/button/ErpButton";


export default {
  name: "AuthorityGroupsManagement",
  components: {
    ErpButton,
    InputText,
    UpdateAuthorityGroupAuthorizationsPopup,
    CreateAuthorityGroupPopup,
    EjsGridWrapper,
    menuSearchComboBox,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  async created() {
    await this.fetchAuthorityGroups();
  },
  data() {
    return {
      selectTreeView:[],
      originData: [],
      addedRecords:[],
      modifyRecord:[],
      treeViewTitle: {
        groupInfo:null,
        groupName:null
      },
      isOpen: false,
      treeData: [],
      searchGroupsQuery: "",
      authorityGroups: [],

      nextOrder: 0,

      visibleCreateAuthorityGroupAuthorizationsPopup: false,
      groupIdCreateAuthorityGroupAuthorizationsPopup: "",
      groupNameCreateAuthorityGroupAuthorizationsPopup: "",

      visibleCreateAuthorityGroupPopup: false,
    };
  },
  computed: {
    fields() {
      return { dataSource: this.treeData, id: 'id', parentID:'parentID', text: 'menuName', child: 'tsMenuList', isChecked: 'value'};
    },
    isPopupOpened() {
      return (
        this.visibleCreateAuthorityGroupPopup ||
        this.visibleCreateAuthorityGroupAuthorizationsPopup
      );
    },
    authorityGroupsGridProps() {
      const dataSource = _orderBy(
        (this.authorityGroups || []).map(
          (
            {
              id,
              name = "",
              description = "",
              order,
              insertUser,
              insertDateTime,
              updateUser,
              updateDateTime,
            },
            index
          ) => ({
            _rid: index + 1,
            id,
            name,
            description,
            order,
            insertUsername: insertUser ? insertUser.name : "",
            insertDateTime,
            updateUsername: updateUser ? updateUser.name : "",
            updateDateTime,
          })
        ),
        "order"
      );

      return {
        allowFiltering: false,
        allowResizing: true,
        allowSelection: true,
        allowSorting: false,
        columns: [
          {
            allowEditing: false,
            field: "id",
            headerText: "ID",
            minWidth: 16,
            textAlign: "Left",
            width: 128,
          },
          {
            allowEditing: false,
            field: "_rid",
            visible: false,
            isPrimaryKey: true,
          },
          {
            allowEditing: true,
            field: "name",
            headerText: "그룹명",
            minWidth: 16,
            textAlign: "Left",
            width: 256,
            type: "string",
          },
          {
            allowEditing: true,
            field: "description",
            headerText: "설명",
            minWidth: 16,
            textAlign: "Left",
            type: "string",
          },
          {
            allowEditing: true,
            field: "order",
            headerText: "정렬",
            minWidth: 16,
            textAlign: "Center",
            width: 64,
            type: "string",
            isNumericType: true,
          },
          {
            allowEditing: false,
            field: "insertUsername",
            headerText: "생성자",
            minWidth: 16,
            textAlign: "Center",
            width: 128,
          },
          {
            allowEditing: false,
            field: "insertDateTime",
            headerText: "생성일시",
            minWidth: 16,
            textAlign: "Center",
            width: 256,
          },
          {
            allowEditing: false,
            field: "updateUsername",
            headerText: "마지막 수정자",
            minWidth: 16,
            textAlign: "Center",
            width: 128,
          },
          {
            allowEditing: false,
            field: "updateDateTime",
            headerText: "마지막 수정일시",
            minWidth: 16,
            textAlign: "Center",
            width: 256,
          },
        ],
        dataSource,
        provides: [Edit, Resize, ExcelExport],
        selectionSettings: { type: "Single" },
      };
    },
  },
  methods: {
    exportMenu(menuId) {
      const args = this.originData.find((f) => f.menuId === menuId);
      this.$refs.treeview.ensureVisible(args.id);
      this.selectTreeView = [menuId];


    },
    treeCheck(args) {
      const standard = args.data[0];
      const auth = ['조회','저장','삭제','인쇄','엑셀'];
      if(standard.isChecked === "true") {
        const trued = args.data.filter((f) => f.isChecked === "true" && auth.includes(f.text));
        const originAuth = this.originData.filter((f) => trued.map(item => item.id).includes(f.id));
        originAuth.forEach((item) => {
          // eslint-disable-next-line no-prototype-builtins
          if(item.hasOwnProperty('groupId') && item.value !== true) {
            this.modifyRecord.push(item);
            // eslint-disable-next-line no-prototype-builtins
          } else if (!item.hasOwnProperty('groupId')){
            this.addedRecords.push(item);
          } else if(this.modifyRecord.findIndex((i) => i.id === item.id) !== -1) {
            this.modifyRecord.splice(this.modifyRecord.findIndex((i) => i.id === item.id),1);
          }
        });
      }
      else {
          const falsed = args.data.filter((f) => f.isChecked === "false" && auth.includes(f.text));
          const originAuth = this.originData.filter((f) => falsed.map(item => item.id).includes(f.id));
          originAuth.forEach((item) => {
            const findidx = this.modifyRecord.findIndex((i) => i.id === item.id);
            // eslint-disable-next-line no-prototype-builtins
            if(item.hasOwnProperty('groupId') && item.value !== false && findidx === -1) {
              this.modifyRecord.push(item);
              // eslint-disable-next-line no-prototype-builtins
            } else if (this.addedRecords.findIndex((i) => i.id === item.id) !== -1){
              this.addedRecords.splice(this.addedRecords.findIndex((i) => i.id === item.id),1);
            }
            if(findidx !== -1) {
              this.modifyRecord.splice(findidx,1);
            }
          });
        }
    },
    async save() {
      const a = this.$refs.treeview.getAllCheckedNodes();
      console.log(a);
      const add = _groupBy(this.addedRecords,'menuId');
      const postAdd = [];
      for (const [key, value] of Object.entries(add)) {
        const obj = {
          menuId:key,
        };
        value.forEach((i) => {
          obj[i.id.replace(`By${i.menuId}`,'')] = !i.value;
        });
        postAdd.push(obj);
      }
      const modify = _groupBy(this.modifyRecord,'menuId');
      const postModify = [];
      for (const [key, value] of Object.entries(modify)) {
        const obj = {
          menuId:key,
        };
        value.forEach((i) => {
          obj[i.id.replace(`By${i.menuId}`,'')] = !i.value;
        });
        postModify.push(obj);
      }
      const post = {
        groupId: this.treeViewTitle?.groupInfo,
        addedRecords: postAdd,
        changedRecords: postModify,
      };
      await GolfErpAPI.putAuthGroups(post);
      await this.infoToast("저장되었습니다");
      await this.fetchTreeView();
    },
    treeViewAllOpen() {
      this.$refs.treeview.expandAll();
    },
    treeViewAllClose() {
      this.$refs.treeview.collapseAll();
    },
    closeTreeView() {
      this.isOpen = false;
    },
    async onSearchGroupsClick() {
      await this.fetchAuthorityGroups();
    },
    onCreateAuthorityGroupClick() {
      this.visibleCreateAuthorityGroupPopup = true;
    },
    onSetAuthorityGroupAuthoritiesClick() {
      const targetRecords =
        this.$refs["authorityGroupsGrid"].getSelectedRecords() || [];

      if (!targetRecords || targetRecords.length === 0) {
        this.errorToast("권한 그룹을 선택해주세요");
        return;
      }

      this.groupIdCreateAuthorityGroupAuthorizationsPopup = targetRecords[0].id;
      this.groupNameCreateAuthorityGroupAuthorizationsPopup =
        targetRecords[0].name;
      this.visibleCreateAuthorityGroupAuthorizationsPopup = true;
    },
    async onDeleteAuthorityGroupClick() {
      const deleteTargetRecords =
        this.$refs["authorityGroupsGrid"].getSelectedRecords() || [];

      if (!deleteTargetRecords || deleteTargetRecords.length === 0) {
        this.errorToast("삭제할 행을 선택해주세요");
        return;
      }

      if (!(await this.confirm("정말로 삭제하시겠습니까?"))) {
        return;
      }

      await Promise.all(
        deleteTargetRecords.map(({ id }) => this.deleteAuthorityGroup(id))
      );

      this.infoToast(this.$t("main.popupMessage.deleted"));

      await this.fetchAuthorityGroups();
    },
    onAuthorityGroupsGridHeaderCellInfo(args) {
      const {
        cell: {
          column: { field },
        },
        node,
      } = args;
      if (field === undefined) {
        node.classList.add(this.$t("className.grid.clickArea"));
      }
      if (field === "id" ) {
        node.classList.add(this.$t("className.grid.clickAreaLeft"));
      }
    },
    onAuthorityGroupsGridQueryCellInfo(args) {
      const {
        cell,
        column: { allowEditing, field },
      } = args;

      if (field === undefined) {
        cell.classList.add(this.$t("className.grid.clickArea"));
      }
      if (field === "id" ) {
        cell.classList.add(this.$t("className.grid.clickAreaLeft"));
      }
      if (allowEditing) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    onAuthorityGroupsGridQueryCellSave(args) {
      const { columnName, previousValue, value } = args;

      if (columnName === "name" && !value.trim()) {
        args.value = previousValue;
      }
    },
    async fetchTreeView() {
      this.addedRecords = [];
      this.modifyRecord = [];
      const data = await GolfErpAPI.getGroupAuth(this.treeViewTitle.groupInfo);
      this.treeData = data;
      this.originData = this.flattenMenuArray(data);
    },
    async onAuthorityGroupsGridQueryCellSaved(args) {
      const {
        rowData: { id },
        columnName,
        previousValue,
        value,
      } = args;

      if (
        columnName !== "name" &&
        columnName !== "description" &&
        columnName !== "order"
      ) {
        return;
      }

      if (previousValue === value) {
        return;
      }

      const patchData = {};
      patchData[columnName] = value;

      await this.updateAuthorityGroup(id, patchData);
      await this.fetchAuthorityGroups();
    },
    flattenMenuArray(menuArray) {
      let flatArray = [];

      function flatten(menuItem) {
        flatArray.push(menuItem);
        if (menuItem.tsMenuList && menuItem.tsMenuList.length > 0) {
          menuItem.tsMenuList.forEach(flatten);
        }
      }

      menuArray.forEach(flatten);

      return flatArray;
    },
    async onAuthorityGroupsGridRecordClick(args) {
      const {
        column,
        rowData: { id, name },
      } = args;
      if (column && column.field === "id") {
        this.treeViewTitle.groupInfo = id;
        this.treeViewTitle.groupName = name;
        this.isOpen = true;
        await this.fetchTreeView();
      } else if(column && column.headerText === "NO"){
        this.groupIdCreateAuthorityGroupAuthorizationsPopup = id;
        this.groupNameCreateAuthorityGroupAuthorizationsPopup = name;
        this.visibleCreateAuthorityGroupAuthorizationsPopup = true;
      } else {
        return;
      }

    },
    async onCreateAuthorityGroupPopupSubmit(payload) {
      await this.createAuthorityGroup(payload);
      this.infoToast("추가되었습니다");
      await this.fetchAuthorityGroups();
    },
    onCreateAuthorityGroupPopupClose() {
      this.visibleCreateAuthorityGroupPopup = false;
    },
    async onCreateAuthorityGroupAuthorizationsPopupClose() {
      this.visibleCreateAuthorityGroupAuthorizationsPopup = false;
      await this.fetchAuthorityGroups();
    },

    async fetchAuthorityGroups() {
      const searchConents = this.searchGroupsQuery
        ? this.searchGroupsQuery
        : null;

      const authorityGroups =
        (await GolfErpAPI.fetchAuthGroupsBySearchContents(searchConents)) || [];

      this.authorityGroups = authorityGroups;
      this.nextOrder =
        (_maxBy(authorityGroups, "order") || { order: 0 }).order + 1;
    },
    async createAuthorityGroup({ id, name, description, order }) {
      await GolfERPService.createAuthorityGroup(
        id,
        name,
        description || undefined,
        order
      );
    },
    async updateAuthorityGroup(groupId, { name, description, order } = {}) {
      await GolfERPService.updateAuthorityGroup(
        groupId,
        name,
        description,
        order
      );
    },
    async deleteAuthorityGroup(groupId) {
      await GolfERPService.deleteAuthorityGroup(groupId);
    },
    onClickExcel() {
      this.$refs.authorityGroupsGrid.excelExport();
    },
  },
};
</script>
