<template>
  <div id="popupWrapper">
    <ejs-dialog
      allowDragging="true"
      header="권한 그룹 - 권한 매핑"
      isModal="true"
      ref="dialog"
      showCloseIcon="true"
      :width="w"
      :height="h"
      v-bind="dialogProps"
      @close="onDialogClose"
      @overlayClick="onDialogOverlayClick"
    >
      <div class="window authorityGroupMapping">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <div class="article-left" style="width: calc(50% - 15px)">
                  <section class="article-section">
                    <div class="section-body" style="border-top-color: #000">
                      <ejs-grid-wrapper
                          ref="authoritiesGrid"
                          v-bind="authoritiesGridProps"
                          @recordClick="authoritiesGridRecordClick"
                      />
                    </div>
                  </section>
                </div>
                <div class="article-center" style="width: 29px">
                  <section class="article-section section-03">
                    <div
                      class="section-body"
                      style="
                        overflow: hidden;
                        border: none;
                        background-color: transparent;
                      "
                    >
                      <ul class="body-button">
                        <li class="add">
                          <erp-button
                              button-div="GET"
                              v-on:click.native="onRegisterClick"
                          >
                            추가
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </section>
                </div>
                <div class="article-right" style="float: left; width: calc(50% - 15px)">
                  <section class="article-section">
                    <div class="section-body" style="border-top-color: #000">
                      <ejs-grid-wrapper
                          ref="authorityGroupAuthoritiesGrid"
                          v-bind="authorityGroupAuthoritiesGridProps"
                          @recordClick="authorityGroupAuthoritiesGridRecordClick"
                      />
                    </div>
                  </section>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-shortcut-button="true"
                  :is-key-color="true"
                  v-on:click.native="onSaveClick"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="onCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.article-section.section-03 {padding: 9px 3px;}
.article-section.section-03 .section-body {display: flex; align-items: center;}
.article-section.section-03 .body-button {display: block; list-style: none; float: left; padding: 1px 0 0 0; margin: 0;}
.article-section.section-03 .body-button li {padding: 4px 0 5px 0;}
.article-section.section-03 .body-button li .e-btn,
.article-section.section-03 .body-button li .e-btn:hover,
.article-section.section-03 .body-button li .e-btn:focus {box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.06);}
.article-section.section-03 .body-button li .e-btn {display: block; overflow: hidden; width: 23px; height: 23px; padding: 0; border-color: #ccc; border-radius: 3px; background: #fff url('../../../assets/images/common/body-button.png') no-repeat center 0; text-indent: -1000px;}
.article-section.section-03 .body-button li .e-btn:hover,
.article-section.section-03 .body-button li .e-btn:focus {background-color: #f0f0f0;}
.article-section.section-03 .body-button li.delete .e-btn {background-position: center -21px;}
</style>

<script>
import {
  flatten as _flatten,
  orderBy as _orderBy,
  maxBy as _maxBy,
  differenceBy as _differenceBy,
} from "lodash";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { Edit, ForeignKey, Group, Resize, Filter, Sort } from "@syncfusion/ej2-vue-grids";
import GolfERPService from "@/service/GolfERPService";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "UpdateAuthorityGroupAuthorizationsPopup",
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  mixins: [confirmDialogMixin],
  async mounted() {
    try {
      await this.$nextTick();
      const $el = document.getElementById('_dialog-header');
      await this.makeFullscreenElement($el);
      await this.makeRefreshElement($el);
      await this.fetchAll();
      this.show();
    } catch (e) {
      this.hide();
      throw e;
    }
  },
  props: {
    groupId: {
      type: String,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      authorities: [],
      groupAuthorities: [],
      menuDataSource: [],
      isFullscreen: true,
    };
  },
  computed: {
    w() {
      return this.isFullscreen ? 'calc(100% - 20px)' : 1180;
    },
    h() {
      return this.isFullscreen ? '100%' : 800;
    },
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
      };
    },
    authoritiesGridProps() {
      const dataSource = _orderBy(
        (this.authorities || []).map(
          (
            {
              authId,
              authName,
              description,
              order,
              lowLevelMenu,
              middleLevelMenu,
              highLevelMenu,
            },
            index
          ) => {
            return {
              _rid: index + 1,
              lowLevelMenuId: lowLevelMenu ? lowLevelMenu.menuId : "",
              middleLevelMenuId: middleLevelMenu ? middleLevelMenu.menuId : "",
              highLevelMenuId: highLevelMenu ? highLevelMenu.menuId : "",
              lowLevelMenuSortNo: lowLevelMenu ? lowLevelMenu.sortNo : 0,
              middleLevelMenuSortNo: middleLevelMenu ? middleLevelMenu.sortNo : 0,
              highLevelMenuSortNo: highLevelMenu ? highLevelMenu.sortNo : 0,
              id: authId,
              name: authName,
              description,
              order,
            };
          }
        ),
        ["highLevelMenuSortNo", "middleLevelMenuSortNo", "lowLevelMenuSortNo", "order"]
      );

      return {
        allowFiltering: true,
        allowGrouping: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        columns: [
          {
            allowEditing: false,
            field: "id",
            visible: false,
          },
          {
            allowEditing: false,
            field: "_rid",
            visible: false,
            isPrimaryKey: true,
          },
          {
            allowEditing: false,
            dataSource: this.menuDataSource,
            field: "highLevelMenuId",
            isCommonCodeField: true,
            headerText: "상위메뉴",
            visible: true,
            width: 80,
          },
          {
            allowEditing: false,
            dataSource: this.menuDataSource,
            field: "middleLevelMenuId",
            isCommonCodeField: true,
            headerText: "중위메뉴",
            visible: true,
            width: 80,
          },
          {
            allowEditing: false,
            dataSource: this.menuDataSource,
            field: "lowLevelMenuId",
            isCommonCodeField: true,
            headerText: "하위메뉴",
            visible: true,
            width: 150,
          },
          {
            allowEditing: false,
            field: "name",
            headerText: "권한",
            minWidth: 16,
            textAlign: "Left",
            width: 150,
          },
          {
            allowEditing: false,
            field: "description",
            headerText: "상세",
            minWidth: 50,
            textAlign: "Left",
          },
          {
            allowFiltering: false,
            allowEditing: false,
            field: "order",
            headerText: "정렬",
            minWidth: 16,
            textAlign: "Center",
            width: 45,
          },
        ],
        dataSource,
        groupSettings: {
          columns: [],
          showDropArea: false,
        },
        isNOColumnDisplay: false,
        provides: [Edit, ForeignKey, Group, Resize, Filter, Sort],
        selectionSettings: { type: "Single", enableToggle: false },
        editSettings: {
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
        },
      };
    },
    authorityGroupAuthoritiesGridProps() {
      const dataSource = _orderBy(
        (this.groupAuthorities || []).map(
          (
            {
              authId,
              authName,
              description,
              order,
              lowLevelMenu,
              middleLevelMenu,
              highLevelMenu,
            },
            index
          ) => {
            return {
              _rid: index + 1,
              lowLevelMenuId: lowLevelMenu ? lowLevelMenu.menuId : "",
              middleLevelMenuId: middleLevelMenu ? middleLevelMenu.menuId : "",
              highLevelMenuId: highLevelMenu ? highLevelMenu.menuId : "",
              lowLevelMenuSortNo: lowLevelMenu ? lowLevelMenu.sortNo : 0,
              middleLevelMenuSortNo: middleLevelMenu ? middleLevelMenu.sortNo : 0,
              highLevelMenuSortNo: highLevelMenu ? highLevelMenu.sortNo : 0,
              id: authId,
              name: authName,
              description,
              order,
            };
          }
        ),
        ["highLevelMenuSortNo", "middleLevelMenuSortNo", "lowLevelMenuSortNo", "order"]
      );

      return {
        allowFiltering: true,
        allowGrouping: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        columns: [
          {
            allowEditing: false,
            field: "id",
            visible: false,
          },
          {
            allowEditing: false,
            field: "_rid",
            visible: false,
            isPrimaryKey: true,
          },
          {
            allowEditing: false,
            dataSource: this.menuDataSource,
            field: "highLevelMenuId",
            isCommonCodeField: true,
            headerText: "상위메뉴",
            width: 80,
            visible: true,
          },
          {
            allowEditing: false,
            dataSource: this.menuDataSource,
            field: "middleLevelMenuId",
            isCommonCodeField: true,
            headerText: "중위메뉴",
            width: 80,
            visible: true,
          },
          {
            allowEditing: false,
            dataSource: this.menuDataSource,
            field: "lowLevelMenuId",
            isCommonCodeField: true,
            headerText: "하위메뉴",
            width: 150,
            visible: true,
          },
          {
            allowEditing: false,
            field: "name",
            headerText: "권한",
            minWidth: 16,
            textAlign: "Left",
            width: 150,
          },
          {
            allowEditing: false,
            field: "description",
            headerText: "상세",
            minWidth: 50,
            textAlign: "Left",
          },
          {
            allowFiltering: false,
            allowEditing: false,
            field: "order",
            headerText: "정렬",
            minWidth: 16,
            textAlign: "Center",
            width: 45,
          },
        ],
        dataSource,
        groupSettings: {
          columns: [],
          showDropArea: false,
        },
        isNOColumnDisplay: false,
        provides: [Edit, ForeignKey, Group, Resize, Filter],
        selectionSettings: { type: "Single", enableToggle: false },
        editSettings: {
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
        },
      };
    },
  },
  methods: {
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onDialogOverlayClick() {
      this.hide();
    },
    async makeFullscreenElement($el) {
      const $copyEl = $el.firstElementChild.cloneNode(true);
      $copyEl.id = 'custom-fullscreen';
      $copyEl.classList.add('fullscreen');
      $copyEl.setAttribute('title', 'Fullscreen');
      $copyEl.setAttribute('aria-title', 'Fullscreen');
      $copyEl.addEventListener('click', () => this.onToggleScreenSizeClick());
      $copyEl.removeEventListener('click', () => this.onToggleScreenSizeClick());
      $el.insertBefore($copyEl, $el.lastChild);
      await this.$nextTick();
    },
    async makeRefreshElement($el) {
      const $copyEl = $el.firstElementChild.cloneNode(true);
      $copyEl.id = 'custom-refresh-';
      $copyEl.classList.add('refresh');
      $copyEl.setAttribute('title', 'Refresh');
      $copyEl.setAttribute('aria-title', 'Refresh');
      $copyEl.addEventListener('click', () => this.fetchAll());
      $copyEl.removeEventListener('click', () => this.fetchAll());
      $el.insertBefore($copyEl, $el.lastChild);
      await this.$nextTick();
    },
    async onToggleScreenSizeClick() {
      this.isFullscreen = !this.isFullscreen;
      await this.$nextTick();
      const $el = document.getElementById('custom-fullscreen');
      $el.classList.remove(!this.isFullscreen ? 'fullscreen' : 'notfullscreen');
      $el.classList.add(this.isFullscreen ? 'fullscreen' : 'notfullscreen');
    },
    authoritiesGridRecordClick(args) {
      // recordClick 이벤트가 rowSelect 보다 먼저 실행됨.
      // deleteRecord() method 정상적인 실행을 위해 selectRow() 실행.
      this.$refs["authoritiesGrid"].selectRow(args.rowIndex);

      const { rowData } = args;

      const authoritiesGridRef = this.$refs["authoritiesGrid"];
      const authorityGroupAuthoritiesGridRef = this.$refs[
        "authorityGroupAuthoritiesGrid"
      ];
      const leftTarget = (
        authoritiesGridRef.getBatchCurrentViewRecords() || []
      ).find((left) => left._rid === rowData._rid);
      if (!leftTarget) {
        return;
      }

      const currentAuthorityGroupAuthoritiesGridRecords =
        authorityGroupAuthoritiesGridRef.getBatchCurrentViewRecords() || [];
      const next_rid =
        (
          _maxBy(currentAuthorityGroupAuthoritiesGridRecords, "_rid") || {
            _rid: 0,
          }
        )._rid + 1;
      const nextOrder =
        (
          _maxBy(currentAuthorityGroupAuthoritiesGridRecords, "order") || {
            order: 0,
          }
        ).order + 1;
      const addingRecord = Object.assign({}, rowData);
      addingRecord._rid = next_rid;
      addingRecord.order = nextOrder;

      authorityGroupAuthoritiesGridRef.addRecord(addingRecord);
      authoritiesGridRef.deleteRecord();
    },
    authorityGroupAuthoritiesGridRecordClick(args) {
      // recordClick 이벤트가 rowSelect 보다 먼저 실행됨.
      // deleteRecord() method 정상적인 실행을 위해 selectRow() 실행.
      this.$refs["authorityGroupAuthoritiesGrid"].selectRow(args.rowIndex);

      const { rowData } = args;

      const authoritiesGridRef = this.$refs["authoritiesGrid"];
      const authorityGroupAuthoritiesGridRef = this.$refs[
        "authorityGroupAuthoritiesGrid"
      ];
      const rightTarget = (
        authorityGroupAuthoritiesGridRef.getBatchCurrentViewRecords() || []
      ).find((right) => right._rid === rowData._rid);
      if (!rightTarget) {
        return;
      }

      const currentAuthoritiesGridRecords =
        authoritiesGridRef.getBatchCurrentViewRecords() || [];
      const next_rid =
        (
          _maxBy(currentAuthoritiesGridRecords, "_rid") || {
            _rid: 0,
          }
        )._rid + 1;
      const nextOrder =
        (
          _maxBy(currentAuthoritiesGridRecords, "order") || {
            order: 0,
          }
        ).order + 1;
      const addingRecord = Object.assign({}, rowData);
      addingRecord._rid = next_rid;
      addingRecord.order = nextOrder;

      authorityGroupAuthoritiesGridRef.deleteRecord();
      authoritiesGridRef.addRecord(addingRecord);
    },
    onRegisterClick() {
      console.log("Test");
    },
    async onSaveClick() {
      await this.updateAuthorityGroupAuthorities(
        this.groupId,
        this.$refs[
          "authorityGroupAuthoritiesGrid"
        ].getBatchChanges()
      );
    },
    onCloseClick() {
      this.hide();
    },

    async fetchAll() {
      const [authorities, groupAuthorities] = await Promise.all([
        GolfERPService.fetchAuthorities(),
        GolfERPService.fetchAuthorityGroupAuthorities(this.groupId),
      ]);

      this.groupAuthorities = groupAuthorities || [];
      this.authorities = _differenceBy(
        authorities || [],
        groupAuthorities || [],
        "authId"
      );

      this.menuDataSource = _flatten(
        this.authorities
          .concat(this.groupAuthorities)
          .map(({ lowLevelMenu, middleLevelMenu, highLevelMenu }) => {
            const t = [];
            if (lowLevelMenu) {
              t.push({
                comCode: lowLevelMenu.menuId,
                comName: lowLevelMenu.menuName,
              });
            }
            if (middleLevelMenu) {
              t.push({
                comCode: middleLevelMenu.menuId,
                comName: middleLevelMenu.menuName,
              });
            }
            if (highLevelMenu) {
              t.push({
                comCode: highLevelMenu.menuId,
                comName: highLevelMenu.menuName,
              });
            }

            return t;
          })
      );
    },
    async updateAuthorityGroupAuthorities(groupId, authorizations) {
      if (
        !(authorizations.addedRecords.length > 0) &&
        !(authorizations.deletedRecords.length > 0)
      ) {
        this.errorToast("수정된 데이터가 없습니다");
        return;
      }

      await GolfERPService.updateAuthorityGroupAuthorities(
        groupId,
        authorizations
      );

      this.infoToast(this.$t("main.popupMessage.saved"));

      // this.hide();
      await this.fetchAll();
    },
  },
};
</script>
